import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, RoutesRecognized } from '@angular/router'
import { filter, map } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl?: string
  hasNavigated = false

  constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof RoutesRecognized &&
            event.urlAfterRedirects !== this.previousUrl
        ),
        map((event) => event as RoutesRecognized)
      )
      .subscribe((event: RoutesRecognized) => {
        setTimeout(() => {
          this.previousUrl = event.urlAfterRedirects
          this.hasNavigated = true
        })
      })
  }

  public back(): void {
    if (this.previousUrl !== undefined) {
      this.location.back()
    } else {
      this.router.navigate(['/'], { replaceUrl: true })
    }
  }
}
